window.sef = window.sef || {};
window.sef.backButton = function () {
    var backButton = $(".back-button");
    var stringValue = "foo";
    if (!history.state  &&  typeof(history.replaceState) == "function"){
        history.replaceState({ page: history.length, href: location.href }, stringValue);
    } 
    if(document.referrer.includes(window.location.host) &&
    window.location.href != document.referrer && 
    window.location.pathname !== "/" &&
    history.state.page !== 1){
        backButton.css('display', 'block');
    }
};

document.addEventListener("DOMContentLoaded", function() {
    window.sef.backButton();
});