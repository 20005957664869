window.sef = window.sef || {};
window.sef.gtmAnchorClickType = function () {
	var socialIconsSelector = 'footer .footer-tools .social';

	function isIgnoreCaptureEvent($targetEl) {
		var ignoreClick = false;
		var $targetHref = $targetEl.attr('href');

		// check if email or phone number
		if ($targetHref.indexOf('mailto:') === 0 ||
			$targetHref.indexOf('tel:') === 0 && $targetHref.length === 4) {
			ignoreClick = true;
		}
		// check if footer social-link
		if ($targetEl.closest(socialIconsSelector).length) {
			ignoreClick = true;
		}
		return ignoreClick;
	}

	$(document).on('click', "a", function () {
		var $targetEl = $(this);
		var $targetType = $targetEl.attr('data-clickType');
		var $targetEventType = $targetEl.attr('data-eventType');
		var $clickText = $(this).text();

		if (isIgnoreCaptureEvent($targetEl)) {
			return;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': $targetEventType ? $targetEventType : 'link-click',
			'clickType': $targetType
				? $targetType + ' click' //ex. mega menu click, right rail click, etc.
				: 'General_link',
			'clickText': $clickText
	});
	});
};

document.addEventListener("DOMContentLoaded", function () {
	window.sef.gtmAnchorClickType();
});
