var $;

(function () {
	"use strict";

	function createCookie(name, value) {
		var date = new Date();
		date.setFullYear(date.getFullYear() + 1);

		document.cookie =
			name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
	}

	$("[data-action='accept-cookie-policy']").click(function () {
		var  data = $("#sef-context-data").data();
		createCookie(data['cookieAlertCookie'], "true");
		$(this).parent(".cookie-alert").hide();
	});

}());
