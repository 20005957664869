window.sef = window.sef || {};

window.sef.drillSizeSwitchUnits = function() {
	var dd = document.getElementById("diameter_drill_size_units");
	var max = document.getElementById("max_drill_size_units");
	var min = document.getElementById("min_drill_size_units");

	var val = dd.options[dd.selectedIndex].value;
	var which = 1;
	if (val === "MM") {
		which = 2;
	}
	max.selectedIndex = min.selectedIndex = which;
	return true;
};

window.sef.drillSizeCalculate = function() {
	var dd = document.getElementById("diameter_drill_size_units");
	var val = dd.options[dd.selectedIndex].value;
	var d = document.getElementById("value-drill-size-nominaldiameter").value;
	var p = document.getElementById("value-drill-size-pitch").value;
	var max = document.getElementById("value-drill-size-maximumdiameter");
	var min = document.getElementById("value-drill-size-minimumdiameter");
	var maxVal, minVal;

	if (val === "Choose") {
		alert("Please choose a proper unit for the nominal diameter");
		return;
	}

	if (val !== "MM") {
		if (p <= 32) {
			maxVal = d - (.64952 * (1 / p));
			minVal = d - (.64952 * (1 / p) * 1.3);
		} else {
			maxVal = d - (0.8 * 0.64952 * (1 / p));
			minVal = d - (.64952 * (1 / p));
		}
	} else {
		if (p >= 0.75) {
			maxVal = d - (.64952 * p);
			minVal = d - (.64952 * p * 1.3);
		} else {
			maxVal = d - (0.8 * 0.64952 * p);
			minVal = d - (.64952 * p);
		}
	}
	max.value = maxVal.toFixed(5);
	min.value = minVal.toFixed(5);
};
