jQuery(function ($) {
	var $calculatorResults = $('.torque-calculator-results.calculator');
	var $calculatorWrapper = $('.torque-calculator.calculator');
	var TCPageTitleStash;

	function viewTorqueCalculatorResults() {
		if (TCcalculateTorque()) {
			$calculatorWrapper.hide();
			$calculatorResults.show();
			if ($(".torque-calculator").length > 0) {
				$("header.page-header p.summary").hide();
				$("header h1").append(" Results");
			}
		}
	}

	function TCresetCalculator() {
		$("#value-nominaldiameter", $calculatorWrapper).val("");
		$("#value-pitch", $calculatorWrapper).val("");
		$("#value-k-factor", $calculatorWrapper).val("");
		$("#value-proof-load-stress", $calculatorWrapper).val("");
		$("#value-length-of-engagement", $calculatorWrapper).val("");
		$calculatorWrapper.show();
		$calculatorResults.hide();
		if ($(".torque-calculator").length > 0) {
			$("header.page-header p.summary").show();
			$("header h1").text(TCPageTitleStash);
		}
	}

	function fillSummaryValues(data) {
		var diameter = data.diameter,
			pitch = data.pitch,
			dryOrLube  = data.dryOrLube,
			kFactor = data.kFactor,
			yieldValue = data.yieldValue,
			lengthEngagement = data.lengthEngagement,
			diameterUnits = data.diameterUnits,
			correctedTorque = data.correctedTorque,
			torqueUnit = data.torqueUnit,
			englishOrMetric = data.englishOrMetric,
			stressUnit = data.stressUnit,
			stressArea = data.stressArea,
			stressAreaUnit = data.stressAreaUnit,
			proofLoad = data.proofLoad,
			loadUnit = data.loadUnit,
			percentOfProof = data.percentOfProof,
			clampLoad = data.clampLoad,
			standardTorque = data.standardTorque,
			splFactor = data.splFactor,
			splTorque = data.splTorque,
			engagement = data.engagement;

		var $summaryKFactor = $calculatorResults.find("#summaryKFactor");
		var $summaryYield = $calculatorResults.find("#summaryYield");
		var $sptcTorqueDry = $calculatorResults.find("#sptcTorqueDry");

		$calculatorResults.find("#summaryDiameter").text(diameter);
		$("#summaryPitch").text(pitch);

		$summaryKFactor.text("")
			.append($("<span></span>").text(dryOrLube))
			.prepend(kFactor);

		$summaryYield.text("")
			.append($("<span></span>").text("PSI"))
			.prepend(yieldValue);

		$calculatorResults.find("#summaryEngagement").text(lengthEngagement);
		$calculatorResults.find("#summaryTorque").text(diameterUnits + " = " + correctedTorque + " " + torqueUnit);
		$calculatorResults.find("div.measurement-units").text(englishOrMetric + " Units");

		$calculatorResults.find("#plcProofLoadStress").text(yieldValue + " " + stressUnit);
		$calculatorResults.find("#plcStressArea").prepend(stressArea + " " + stressAreaUnit);
		$calculatorResults.find("#plcProofLoad").text(proofLoad + " " + loadUnit);
		$calculatorResults.find("#clcProofLoad").text(proofLoad + " " + loadUnit);
		$calculatorResults.find("#clcPercentOfProof").prepend(percentOfProof);
		$calculatorResults.find("#clcClampLoad").text(clampLoad + " " + loadUnit);
		$calculatorResults.find("#stcClampLoad").text(clampLoad + " " + loadUnit);
		$calculatorResults.find("#stcDiameter").text(diameter + " " + diameterUnits);
		$calculatorResults.find("#stcKFactor").text(kFactor);
		$calculatorResults.find("#stcTorque").text(standardTorque + " " + torqueUnit);
		$calculatorResults.find("#sptcTorque").text(standardTorque + " " + torqueUnit);
		$calculatorResults.find("#sptcTenPercentSL").text(splFactor + " " + torqueUnit);

		$sptcTorqueDry.text("")
			.append($("<strong></strong>").text("(Dry)"))
			.prepend(splTorque + " " + torqueUnit + "  ");

		$calculatorResults.find("#lecTorque").text(splTorque + " " + torqueUnit);
		$calculatorResults.find("#lecPercentEngagement").prepend(engagement);
		$calculatorResults.find("#lecCorrectedTorque").text(correctedTorque + " " + torqueUnit);
	}

	function TCcalculateTorque() {
		var diameter = $("#value-nominaldiameter").val();
		var diameterUnits = $("#units-nominaldiameter > select").val();
		var pitch = $("#value-pitch").val();
		var kFactor = $("#value-k-factor").val();
		var yieldValue = $("#value-proof-load-stress").val();
		var lengthEngagement = $("#value-length-of-engagement").val();
		var percentOfProof = "0.75";

		var torqueUnit, stressUnit, loadUnit, stressAreaUnit, diameterUnitAbbr, englishOrMetric;
		if (diameterUnits === "Inches") {
			torqueUnit = "Ft-lb";
			stressUnit = "PSI";
			loadUnit = "pounds";
			stressAreaUnit = "Inch";
			diameterUnitAbbr = "inch";
			englishOrMetric = "English";
		} else {
			torqueUnit = "Nm";
			stressUnit = "MPa";
			loadUnit = "kN";
			stressAreaUnit = "MM";
			diameterUnitAbbr = "MM";
			englishOrMetric = "Metric";
		}

		if (!$.isNumeric(diameter) || !$.isNumeric(pitch) || !$.isNumeric(kFactor) || !$.isNumeric(yieldValue) || !$.isNumeric(lengthEngagement)) {
			alert("All values are required and must be numbers");
			return false;
		}

		var stressArea;
		if (diameterUnits === "Inches") {
			stressArea = (0.7854 * (Math.pow((diameter - (0.9743 / pitch)), 2))).toFixed(6);
		} else {
			stressArea = (0.7854 * Math.pow(diameter - (0.9382) * pitch, 2)).toFixed(6);
		}

		var proofLoad;
		if (diameterUnits === "Inches") {
			proofLoad = Math.round(stressArea * yieldValue);
		} else {
			proofLoad = Math.round(stressArea * yieldValue) / 1000;
		}

		var clampLoad = Math.round(proofLoad * .75, 0);

		var standardTorque;
		if (diameterUnits === "Inches") {
			standardTorque = ((clampLoad * diameter * kFactor) / 12).toFixed(1);
		} else {
			standardTorque = (clampLoad * diameter * kFactor).toFixed(1);
		}

		var dryOrLube = kFactor > .15 ? "Dry" : "Lubed";

		var splFactor = (standardTorque * .10).toFixed(1);
		var splTorque = (standardTorque * 1.1).toFixed(1);
		var engagement = Math.round(lengthEngagement / diameter * 100);
		var correctedTorque = (splTorque * engagement / 100).toFixed(2);
		///////////

		// Fill Summary Values
		fillSummaryValues({
			diameter: diameter,
			pitch: pitch,
			dryOrLube : dryOrLube,
			kFactor: kFactor,
			yieldValue: yieldValue,
			lengthEngagement: lengthEngagement,
			diameterUnits: diameterUnits,
			correctedTorque: correctedTorque,
			torqueUnit: torqueUnit,
			englishOrMetric: englishOrMetric,
			stressUnit: stressUnit,
			stressArea: stressArea,
			stressAreaUnit: stressAreaUnit,
			proofLoad: proofLoad,
			loadUnit: loadUnit,
			percentOfProof: percentOfProof,
			clampLoad: clampLoad,
			standardTorque: standardTorque,
			splFactor: splFactor,
			splTorque: splTorque,
			engagement: engagement
		});

		return true;
	}

	function TCswitchUnits() {
		var diameterUnits = $("#units-nominaldiameter > select");
		var pitchUnits = $("#units-pitch > select");
		var yieldUnits = $("#units-proof-load-stress > select");
		var lengthUnits = $("#units-length-of-engagement > select");

		var index = diameterUnits.prop('selectedIndex');

		pitchUnits.prop('selectedIndex', index);
		yieldUnits.prop('selectedIndex', index);
		lengthUnits.prop('selectedIndex', index);

		return true;
	}


	function init() {
		var isCalarFound = $calculatorWrapper.length;
		if(!isCalarFound) return;
		$(".torque-calculator-results.calculator").hide();
		$("#torque-calculator-button > .btn.transparent").click(viewTorqueCalculatorResults);
		$("#torque-calculator-button-back").click(TCresetCalculator);
		$("#units-nominaldiameter > select").change(TCswitchUnits);
		TCPageTitleStash = $("header h1").text();
	}

	init();
});
