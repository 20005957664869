var $;
var jQuery;
var ssm;
var sef;
var window;
var StickySidebar;

jQuery(function ($) {
	"use strict";

	// Add a class for devices just in case need to target for any purpose
	var platformAndBrowser = navigator.userAgent;
	if (platformAndBrowser.match(/iPad/i)) {
		$('body').addClass('ipad');
	} else if (platformAndBrowser.match(/iPhone/i)) {
		$('body').addClass('iphone');
	} else if (platformAndBrowser.match(/Android/i)) {
		$('body').addClass('android');
	}

	// Test to see if user is in SC Editor mode to change JS functions, such as
	// disabling the carousel, for the content editor
	var expEditor = $('.hdnExpEditor').val() === 'true';

	// Mobile Device Functions
	ssm.addState({
		id: 'mobile',
		query: '(max-width: 959px)',
		onEnter: function () {
		// Footer Nav
			$('.footer-nav .toggle').parent().on('click', function () {
				$(this).children('.toggle').toggleClass('active');
				$(this).closest('section').find('ul').toggleClass('active');
			});
		// Footer Country/Language Toggles
			$('.footer-tools .select-toggle>div').on('click', function(){
				$(this).toggleClass('shown');
				$(this).siblings('ul').toggleClass('shown');
			});
		// Mobile Nav
		// First, frame out components
			$( '.bottom-wrap' ).wrapAll( '<div class=\'bottom-frame\'><div class=\'b-frame\' /></div>');
			var bottomFrame = $(".bottom-frame");
		// Nav Toggle Actions
		// Delay class removal to allow animation to complete before changing styling
		function delayClassMod(classname, durationTarget) {
			setTimeout(function() {
				bottomFrame.removeClass(classname);
			}, sef.duration(durationTarget) );
		}
		// Top hero background image size
		$(".top-hero").each(function() {
			var
				bg = $(this).find(".backgrounds"),
				img = bg.find(".mobile");

			$(window).on("load", function() {
				function setTopHeroImgSize() {
					bg.removeClass("wide tall");
					img.removeClass("wide tall");
					var
						w = bg.outerWidth(),
						h = bg.outerHeight();
					// Add an aspect ratio-based classname to the image wrapper
					if ( w >= h ) {
						bg.addClass("wide");
					}
					else {
						bg.addClass("tall");
					}
					// Add an aspect ratio-based classname to the image
					if ( img.outerHeight() <= img.outerWidth() ) {
						img.addClass("wide");
					}
					else if ( img.outerHeight() > img.outerWidth() ) {
						img.addClass("tall");
					}
					// Final checks need to be done because we can't check both
					// dimensions until either a wide or tall class is added
					// Double-check to make sure there's no extreme aspect ratio
					if( img.outerWidth() < w && img.outerHeight() === h ) {
						img.removeClass("wide").addClass("tall");
					}
					// Double-check to make sure there's no extreme aspect ratio
					else if( img.outerHeight() < h && img.outerWidth() === w ) {
						img.removeClass("tall").addClass("wide");
					}
					bg.addClass("is-visible");
				}
				setTopHeroImgSize();

				$(window).on("resize", function() {
					setTopHeroImgSize();
				});
			});
		});
		// Show Site Nav
			$('.nav-toggle').on('click', function(){
				if($('.nav-toggle.active')[0]) {
					// Reset menu when user closes it
					$('.main-nav').find('.second-level').removeClass('second-level');
					$('.main-nav dd ul').scrollTop(0);
				}
				$(this).toggleClass('active');
				$('body, .site-header').toggleClass('nav-shown');
			});
		// Show Second Level Nav
			$('.top-item').on('click', function(e){
				$(this).parent().addClass('second-level');
				bottomFrame.addClass("second-level-active");
				delayClassMod("third-level-active", ".three-levels dd");
				e.preventDefault();
			});
		// Go Back to First Level Nav
			$('.subnav>.toggle-back').on('click', function(){
				$(this).closest('.second-level').removeClass('second-level');
				delayClassMod("third-level-active second-level-active", ".three-levels dd");

			});
			$('dl .toggle-back').on('click', function(){
				$(this).closest('.third-level').removeClass('third-level');
				bottomFrame.addClass("second-level-active");
				delayClassMod("third-level-active", ".three-levels dd");
				$('.main-nav dd ul').scrollTop(0);
			});
		// Show Third Level Nav
			$('.second-item').on('click', function(){
				$(this).closest('dl').addClass('third-level');
				bottomFrame.removeClass("second-level-active").addClass("third-level-active");
			});
		// Show Search
			$('.search-toggle').on('click', function(){
				$(this).toggleClass('active');
				$('.site-search').toggleClass('active');
				$('.search-mega').toggleClass('active');
				$('.global-search').toggleClass('active');
				if ($('.nav-shown')[0]) {
					// Reset main menu and close
					$('.main-nav dd ul').scrollTop(0);
					$('.third-level').removeClass('third-level');
					$('.second-level').removeClass('second-level');
					$('body, .site-header').removeClass('nav-shown');
					$('.nav-toggle').removeClass('active');
				}
			});
		// Select toggles in main nav
			$('.tool-nav .select-toggle').on('click', function(){
				$(this).addClass('active');
			});
			$('.tool-nav .toggle-back').on('click', function(event){
				event.stopPropagation();
				$('.select-toggle.active').addClass('animate-out');
				setTimeout(function() {
					$('.select-toggle.active').removeClass('active').removeClass('animate-out');
				}, 500 );
			});
		// Select  Toggle on Mobile
			$('.resources-selector .select-toggle>div').on('click',function(){
				$(this).toggleClass("shown").siblings('ul').toggleClass('shown');
			});
		},
		onLeave: function () {
			// Reload the page if the user resizes the browser between the mobile and
			// the desktop breakpoints. Mainly exists for user testing scenerios
			if (!expEditor) {
				location.reload();
			}
		}
	});

	// Desktop Functions
	ssm.addState({
		id: 'non-mobile',
		query: '(min-width: 960px)',
		onEnter: function() {
			// Functions that happen on mobile are here
			// Clone Quick Links into subnav
			$('.quick-links').clone().appendTo('.subnav');

			// Apply image as hero background
			if ($('.backgrounds')[0]) {
				$('.top-hero').each(function () {
					$(this).css('background-image', 'url(' + $(this).find('.desktop').prop('src') + ')');
					$(this).find('.backgrounds').hide();
				});
				$('.page-hero').each(function () {
					$(this).find(".top").css('background-image', 'url(' + $(this).find('.desktop').prop('src') + ')');
					$(this).find('.backgrounds').hide();
				});
			}
			// For frontend dev static
			if ( $("#site-search input")[0] ) {
				// Show Search
				$("#site-search input").on("focus", function() {
					$('.search-mega').addClass('active');
				});
				$(".search-mega .close").on("click", function() {
					$('.search-mega').removeClass('active');
				});
			}
			// Sticky Nav
			if (!expEditor) {
				var prev = 0;
				var additionalGap = 10;
				var $window = $(window);
				var nav = $('header');
				var navHeight = nav.outerHeight();
				var main = $('main');
				main.css('padding-top', navHeight);
				$window.on('scroll', function(){
					var scrollTop = $window.scrollTop();
					if(scrollTop + additionalGap > navHeight) {
						nav.toggleClass('unpinned', scrollTop > prev);

						//upd for IE: wait until scrollTop recalculated
						setTimeout(function(){
							prev = scrollTop;
						}, 250);
					}
				}).on("resize", function() {
					// On resize, make sure the <main> container is has correct padding
					var navHeightNew = $('header').outerHeight();
					if(navHeightNew !== navHeight) {
						navHeight = navHeightNew;
						main.css('padding-top', navHeightNew);
					}
				});
			}

			// Mega Menu
			$('.top-item').parent('li').each(function() {
				var subnav = $(this).find('.subnav');
				//Add some space in bottom of subMenu
				var bottomIntent = 20;

				$(this).on('mouseenter', function(){
					var deviceHeight = $(window).height();
					var newSubMenuHeight = deviceHeight/2;
					var $cookieAlertShow = $('.cookie-alert');
					var $cookieAlertHeight = ($cookieAlertShow.hasClass('shown')) ? $cookieAlertShow.height() : 0;

					// Get height on every hover in case window width changes:
					// columns + quicklinks + 20px padding
					var subMenuHeight = subnav.children(".columns").outerHeight() +
						subnav.children(".quick-links").outerHeight() + bottomIntent;

					subnav.attr("data-height", subMenuHeight);
					subnav.removeClass('animating').addClass('active').height(subnav.data("height"));
					// Temporarily increase the header height to allow the meganav
					// to scroll on small screens, but only if the meganav is
					// taller than the viewport
					if ( (subnav.data("height") + bottomIntent) > (deviceHeight - $cookieAlertHeight) ) {
						subnav.attr("data-height", newSubMenuHeight)
							.removeClass('animating').addClass('active').height(newSubMenuHeight).css('overflow-y', 'scroll');
					} else {
						subnav.css('overflow-y', 'hidden');
					}
					$('.search-mega').removeClass('active');
					$('.global-search').removeClass('magic-box-hasFocus');
					$('.magic-box-input input').blur();
				});

				$(this).on('mouseleave', function(){
					subnav.removeClass('active').addClass('animating').removeAttr("style");
					$(".site-header").removeAttr("style");
					setTimeout(function() {
						subnav.removeClass('animating');
					}, sef.duration(subnav) );
				});
			});

			$('.subnav .close').on('click', function(){
				$(this).parent('.subnav').removeClass('active');
			});
		},
		onLeave: function () {
			// Reload the page if the user resizes the browser between the mobile and
			// the desktop breakpoints. Mainly exists for user testing scenerios
			if (!expEditor) {
				location.reload();
			}
		}
	});

	// Form 123
	if ($('.registration-form').length) {
		function registrationFormStyling () {
			var formField = ".registration-form__field-container";
			var $formField = $(".registration-form__field-container");

			if($formField.length) {
				$(".registration-form__field-container :input").on('focus', function(){
					$(this).parents(formField).addClass('active');
					$formField.not($(this).parents(formField)).removeClass('active');
				});
				$('.registratin-form__checkbox-field input[type=checkbox]').after('<div class="checkbox-field__checkmark"></div>');
				$('.registration-form__field-container :input[data-val-required]').parents(formField).addClass('required-field');
			}
		}

		registrationFormStyling();

		$('.registration-form input[type="submit"]').on( "click", function() {
			var onRegSubmit = setInterval(regTimer, 1);

			function regTimer() {
				if (!$('.checkbox-field__checkmark').length) {
					registrationFormStyling();
					regTimerStopper();
				}
			}

			function regTimerStopper() {
				clearInterval(onRegSubmit);
			}
		});
	}

	//Shared Functions
		// Wrap Every Two Slides in a container, then start the carousel if not in the experience editor
		if (!$('body').hasClass('sc-editor')) {
			// The carousel can either have a class of "carousel-single" or "carousel-double". If we have a double, group every 2 slides together.
			if ($('.carousel-double').length > 0) {
				var productdivs = $(".carousel-double .slides .slide");
				var i;
				for (i = 0; i < productdivs.length; i += 2) {
					productdivs.slice(i, i + 2).wrapAll("<div class='slide-group'></div>");
				}
			}
			// Do we have a carousel? If so, wow many carousels do we have? Let's loop through them and initialize each with the dots and autoplay settings from the cms added as css styles.
			if ($('.carousel').length > 0) {
				for (var i = 0; i < $('.carousel').length; i++) {
					var carouselDots = ($('.carousel')[i].classList.contains('carousel-has-dots')) ? true : false;
					var carouselAutoPlay = ($('.carousel')[i].classList.contains('carousel-autoplay')) ? true : false;
					$('.carousel .slides').eq(i).slick({
						arrows: true,
						dots: carouselDots,
						autoplay: carouselAutoPlay,
						autoplaySpeed: 4000
					});
				}
			}

			}
		// Lightboxes
			$('.open-lb').magnificPopup({
				type: 'inline',
				preloader: true,
				modal: false,
				closeOnBgClick: false
			});
			$('.open-video').magnificPopup({
				disableOn: 700,
				type: 'iframe',
				removalDelay: 160,
				preloader: false,
				fixedContentPos: false
			});
			if ($('.lang-alert')[0]) {
				$.magnificPopup.open({
					items: {
						src: '.lang-alert'
					},
					type: 'inline'
				}, 0);
			}
		// Cookie Alert
			if ( $('.cookie-alert')[0] ) {
				var cookieHeight = $('.cookie-alert').height();
				$('.site-footer').css('padding-bottom', cookieHeight+'px');
				$('.cookie-alert').addClass('shown');
				$('.cookie-alert button').on('click', function(){
					$('.cookie-alert').removeClass('shown');
					$('.site-footer').css('padding-bottom', '');
				});
			}

	//Optanon Cookies
	function cookiesAreEnabled(groupNum) {
    var cookiesName = 'OptanonConsent',
        groupsItems = [],
        cookiesValue,
        cookieString,
        groupsString,
        groupsArray;

    cookiesValue = document.cookie.match(new RegExp(
        "(?:^|; )" + cookiesName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));

    if (cookiesValue == null) {
        return true;
    }

    cookieString = cookiesValue ? decodeURIComponent(cookiesValue[1]) : undefined;

    groupsString = cookieString.substr(cookieString.indexOf('groups') + 7, 15);

    groupsArray = groupsString.split(',');

    for (var i = 0; i < groupsArray.length; i++) {
        var current = groupsArray[i].split(':');

        groupsItems.push(current[1]);
    }

    return parseInt(groupsItems[groupNum - 1]) ? true : false;
	}

	function cookieExist(cookieName) {
		if (document.cookie.split(';').filter(function(item) {
				return item.trim().indexOf(cookieName) == 0
		}).length) {
				return true
		}
	}

	$(document).ready(function () {
		// Coveo analytics
		if (cookieExist('Optanon') && cookieExist('coveo') && (!cookiesAreEnabled(2) || !cookiesAreEnabled(3))) {
			var coveoSearch = document.querySelector('.CoveoSearchInterface');
			Coveo.disableAnalytics(coveoSearch);
		}
	});
});

var collapseExpand = function (obj, bottomMarginVal) {
	var openclass = "is-open";
	var	isExpandAllAccordian = false;
	var layer = obj.next();
	var	h = layer.children(".inner").outerHeight();
	//The outerHeight ignores the bottom margin value when setting the height using Expand/Collapse All, so we add that here.
	if (bottomMarginVal) {
		h += bottomMarginVal;
	}
	// If layer is not open, open it
	if (!layer.hasClass(openclass)) {
		obj.addClass(openclass);
		layer.addClass(openclass).css("height", h);
		// I am commenting this out because it was preventing the closing animation when toggling single items.
		// After animation is complete, remove the animated height style
		//setTimeout(function () {
		//	layer.removeAttr("style");
		//}, sef.duration(layer));
	}
	// If layer is open, close it
	else {
		obj.removeClass(openclass);
		layer.css("height", layer.children(".inner").outerHeight());
		layer.removeClass(openclass).removeAttr("style");
	}

	// The following is only for Expand All Tabs / Collapse All Tabs accordians
	var howManySiblings = obj.siblings().length;
	var howManyTriggers = 0;
	var howManyOpen = 0;
	var whichOneIsExpandAll = 0;

	for (var i = 0; i < howManySiblings; i++) {
		if (obj.siblings()[i].id == 'expandAll') {
			//One of the siblings is the expandAll
			isExpandAllAccordian = true;
			whichOneIsExpandAll = i;
		}
		// Since when i is 0 its the header or 1 its the expand all
		if (i > 1) {
			if (layer.siblings()[i].classList.contains("is-open")) {
				howManyOpen++;
			}
			if (layer.siblings()[i].classList.contains("trigger")) {
				howManyTriggers++;
			}
		}
	}

	// Is this accordian one with an expand collapse all? If so, we'll see if we need update the expand all collapse all text.
	if (isExpandAllAccordian) {
		// All closed
		if (howManyOpen == 0) {
			obj.siblings()[whichOneIsExpandAll].classList.remove("is-open");
			obj.siblings()[whichOneIsExpandAll].children[0].innerText = "Expand All Tabs";
		}
		//All open
		if (howManyOpen >= (howManyTriggers * 2) -1) {
			obj.siblings()[whichOneIsExpandAll].classList.add("is-open");
			obj.siblings()[whichOneIsExpandAll].children[0].innerText = "Collapse All Tabs";
		}
	}

};

/* For the expand all tabs and close all tabs accordian functionality */
var expandAll = function (obj, bottomMarginVal) {
	var openclass = "is-open";
	var layer = obj.next();
	var	h = layer.children(".inner").outerHeight();
	//The outerHeight ignores the bottom margin value when setting the height using Expand/Collapse All, so we add that here. 
	if (bottomMarginVal && (h != null)) {
		h += bottomMarginVal;
	}

	if (!obj.hasClass(openclass)) {
		obj.addClass(openclass);
	}

	if (!layer.hasClass(openclass)) {
		layer.addClass(openclass).css("height", h);
	}

};

var closeAll = function (obj) {
	var openclass = "is-open";
	var layer = obj.next();
	obj.removeClass(openclass);
	layer.css("height", layer.children(".inner").outerHeight());
	layer.removeClass(openclass).removeAttr("style");
};

sef = (function () {
	"use strict";
	// Checks to see if everything really loaded before calling sef.init() This replaces $(document).ready function which was firing while document.readyState was "interactive".
	var intervalLoadCheck = setInterval(function () {
		if (document.readyState === 'complete') {
			clearInterval(intervalLoadCheck);
			sef.init();
		}
	}, 100);

	return {
		init : function() {
			sef.accordion(".accordion");
			sef.blockquote_fancy(".blockquote-fancy");
			sef.showhide(".showhide");
			sef.cube_grid(".cube-grid");
			sef.blockquote("blockquote");
			sef.hl_vertical(".highlight-vertical");
			sef.gallery(".gallery");
			sef.resources_selector_linky(".resources-selector");
			sef.widown_t(".page-hero.background-bottom");
		},


		//
		// Show/Hide accordion
		//
		accordion : function(el) {
			$(el).each(function() {

				var
					obj = $(this),
					triggers = obj.find(".trigger"),
					layers = obj.find("section");

				layers.addClass("collapsed");

				triggers.on("click", function(event) {
					event.preventDefault();
					// Are we opening or closing all of the items in the accordian?
					if (this.id === "expandAll") {
						// close them all
						if ($(this).hasClass("is-open")) {
							this.querySelector("*").innerText = "Expand All Tabs";
							for (var i = 0; i < triggers.length; i++) {
								closeAll($(triggers[i]));
							}
						}
						// open them all
						else {
							this.querySelector("*").innerText = "Collapse All Tabs";
							for (var i = 0; i < triggers.length; i++) {
								expandAll($(triggers[i]), 12);
							}
						}
					// Only toggle a single accordian item
					} else {
						collapseExpand($(this), 12);
					}
				});
			});
		},


		//
		// Check to see if the .blockquote-fancy element has an image, if it
		// does, add some classes for styling
		//
		blockquote_fancy : function(el) {
			$(el).each(function() {
				var
					obj = $(this),
					img = obj.find("img");
				if( img.length ) {
					obj.addClass("has-image");
					return;
				}
			});
		},


		//
		// Show/Hide layer & toggle combination
		//
		showhide : function(el) {
			$(el).each(function() {
				var
					obj = $(el),
					toggle = obj.children(".toggle"),
					layer = obj.children(".layer"),
					inner = layer.children(".inner"),
					def = toggle.text(),
					alt = toggle.data("alt"),
					classname = "is-open";
				// Set the height on the show/hide layer to create animation
				function setHeight() {
					// Set the height
					layer.css("height", inner.outerHeight()+toggle.outerHeight());
					// Once animation is complete
					setTimeout(function() {
						// Remove the inline style so the box can grow if its
						// content grows
						layer.removeAttr("style");
					}, sef.duration(layer) );
				}
				// When clicking the toggle
				toggle.on("click", function(event) {
					event.preventDefault();
					// Check for open classname. If it does not exist
					if ( !obj.hasClass(classname) ) {
						// Set the layer height
						setHeight();
						// Add the open class
						obj.addClass(classname);
						// If alt text exists, change it
						if( alt.length ) {
							toggle.text(alt);
						}
					}
					// If the open classname does exist
					else {
						// Add the height back so it can animate
						layer.css("height", (inner.outerHeight()+toggle.outerHeight()));
						// After a quick delay
						setTimeout(function() {
							// Remove the inline style to create animation effect
							layer.removeAttr("style");
						}, 10 );
						// Remove the open class
						obj.removeClass(classname);
						// If alt text exists, change it back to default
						if( alt.length ) {
							toggle.text(def);
						}
					}
				});
				// On resize, make sure the showhide container is tall enough
				$(window).on("resize", function() {
					if ( obj.hasClass(classname) && $(window).width() < 960 ) {
						layer.removeAttr("style");
						setHeight();
					}
				});
			});
		},


		//
		// Functions for cube-grid
		//
		cube_grid : function(el) {
			$(el).each(function() {
				var
					grid = $(this),
					items = grid.children(".card"),
					flippers = grid.find(".flipper"),
					ref = $(items[0]),
					h;

				// Check to see if the card has a back side.
				items.each(function() {
					var backside = $(this).find(".back");
					// If not, add a class to prevent flipping
					if ( backside.length < 1 ) {
						$(this).addClass("no-flip");
					}
					// If there is a backside, add a toggle for mobile
					else {
						// If there is no toggle button, add butoon for mobile
						if(!$(this).find(".flip-toggle")){
							$(this).append("<div class=\"flip-toggle\"></div>");
						}
						var toggle = $(this).find(".flip-toggle");
						toggle.on("click", function(event) {
							event.preventDefault();
							$(this).closest(".card").toggleClass("is-flipped");
						});
					}
				});

				// When hovering, add a class that flips the card
				// Doing it with classname instead of :hover so we can keep
				// streamline mobile functionality
				if ( $(window).width() >= 960 ) {
					items.hover(
						function() {
							$(this).addClass("is-flipped");
						}, function() {
							$(this).removeClass("is-flipped");
						}
					);
				}

				// Make all items square by matching the height to existing width
				function gridResize() {
					h = ref.outerWidth();
					items.css("min-height", h);
					flippers.css("height", h);
				}
				// On load
				gridResize();
				// On window resize
				$(window).on("resize", function() {
					setTimeout(function() {
						gridResize();
					}, 200);
				});
			});
		},


		//
		// Add the start quote icon to all blockquotes
		//
		blockquote : function(el) {
			$(el).each(function() {
				var obj = $(this);
				if (obj.attr("class") && obj.attr("class").includes("fancy") && !obj.attr("class").includes("quote-background-black") ) {
					obj.find(".quote").prepend("<i class=\"icon-quote\"></i>");
				}
				if (obj.attr("class") && obj.attr("class").includes("fancy") && obj.attr("class").includes("quote-background-black")) {
					obj.find("p").first().prepend("<i class=\"icon-quote\"></i>");
					obj.find("p").last().append("<i class=\"icon-quote\"></i>");
				}
			});
		},

		//
		// 1. Disable links in sitecore experience editor
		// 2. Handle item clicks
		// 3. Check to see how many columns are in the highlight-vertical component
		// If there are four, add a class so we can shrink the margins a bit
		// 4. Look through items and if there's no image, add a noimage class
		//
		hl_vertical : function(el) {
			$(el).each(function() {
				var
					obj = $(this),
					items = obj.find(".item"),
					count = items.length;

				if ($('body').hasClass('sc-editor')) {
					obj.find("a").on("click", function(event) {
						event.preventDefault();
					});
				}
				else {
					// Clickable item modules
					$('.highlight-vertical .item').on('click', function () {
            var theLink = $(this).find("a");
            if (theLink.length) {
                theLink = theLink.addBack("a").attr("href");
								window.location.href = theLink;
						}
					});
				}

				// Make sure each image is cropped to remain the same height
				function cropImage() {
					obj.find("img").each(function() {
						$(this).wrap("<div class=\"image\">");
						var h = $(this).outerWidth()*0.5321100917;
						$(this).parent(".image").css("height", h);
					});
				}

				// If there are more than four items, create a carousel from this component
				if ( items.length > 4 && obj.hasClass("is-carousel") ) {

					cropImage();
					// Trigger a slideshow on this element (but only if it's not
					// being viewed within the Sitecore Experience Editor)
					if (!$('body').hasClass('sc-editor')) {
						$(this).find(".grid").slick({
							arrows: true,
							dots: true,
							mobileFirst: true,
							slidesToShow: 1,
							slidesToScroll: 1,
							responsive: [
								{
									breakpoint: 960,
									settings: {
										slidesToShow: 4,
										slidesToScroll: 4
									}
								}
							]
						});
					}
				}
				// If four or less items exist AND this is not an index-large component
				else if( !obj.hasClass("index-large") ) {
					// Add class for styling
					obj.addClass("highlight-cols-" + count).removeClass("is-carousel");
				}
				// Check for image. If no image, add noimage class
				items.each(function() {
					var img = $(this).find("img");
					if(img.length === 0) {
						$(this).addClass("noimage");
					}
				});

			});
		},

		//
		// Image / Content Gallery
		//
		gallery : function(el) {
			$(el).each(function() {

				var
					obj = $(this),
					galleryWindow = obj.find('.gallery-window'),
					items = obj.find('.item'),
					count = items.length,
					activeclass = 'is-active',
					prevclass = 'was-active',
					noanimate = 'no-animation',
					ltrani = 'ltr-animation',
					active = 0,
					speed = sef.duration(items),
					links = [],
					canclick = true,
					ani = false,
					dimSet = false,
					alt = false,
					current, nav, navitems, navitem, navbtn, arrows, prev, next, h;

				if ( !obj.find(".text").length ) {
					alt = true;
				}

				// Slide the items to the left
				function goNext() {
					ani = true;
					// Add activeclass so it knows where to go next
					next.addClass(activeclass);
					// Remove the current active class
					current.removeClass(activeclass).addClass(prevclass);
					// Set a timeout so it doesn't get moved too quickly
					setTimeout(function() {
						// Removing noanimate & prevclass at the same time causes LTR animation
						next.removeClass(noanimate+' '+prevclass);
					}, 50 );
				}
				// Slide the items to the right
				function goPrev() {
					ani = true;
					// Add prevclass to move it left, add noanmiate so it doesn't animate
					next.addClass(prevclass+' '+noanimate);
					// Add activeclass so it knows where to go next
					next.addClass(activeclass);
					// Remove the current active class
					current.removeClass(activeclass).addClass(prevclass).addClass(ltrani);
					// Set a timeout so it doesn't get moved too quickly
					setTimeout(function() {
						// Removing noanimate & prevclass at the same time causes LTR animation
						next.addClass(ltrani).removeClass(noanimate+' '+prevclass);
					}, 50 );
				}

				// Set the gallery window height
				function setIt(h, num) {
					galleryWindow.height(h);
					$(items[num]).height(h);
					setTimeout(function() {
						items.not($(items[num])).removeAttr('style');
					}, speed);
				}

				// Set gallery window dimensions
				function setDimensions(num) {
					if( ani === false ) {
						h = $(items[num]).outerHeight();
						if ( alt === true ) {
							h = $(items[num]).find("img").outerHeight();
						}
						setIt(h, num);
					}
					else if( dimSet === false ) {
						setTimeout(function() {
							setDimensions(num);
						}, speed );
						dimSet = true;
					}
				}

				// Animate the tray
				function animateTray(num, arrow) {
					current = $(items[active]);
					// If this is the end of the line, reset to 0
					if (num === count) {
						num = 0;
					}
					// Prev arrow was clicked while active was 0, so set num to the last item
					else if (num < 0) {
						num = count - 1;
					}
					next = $(items[num]);
					// If the arrow was *not* clicked and going from first to last
					/*if( arrow !== true && active === 0 && num === count-1 ) {
						setDimensions(num);
						goNext();
					}*/
					// If next is lower than active, or
					// If clicking a bullet and going from last to first, or
					// If going to a lower num but not back to first, or
					// When going from last to first
					if ((active === 0 && num === count - 1) ) {
						setDimensions(num);
						goPrev();
					}
					else if ((num === 0 && active === count - 1))
					{
						setDimensions(num);
						goNext();
					}

					else if (num < active)
					{
						setDimensions(num);
						goPrev();
					}
					// Everything else
					else {
						setDimensions(num);
						goNext();
					}
					// After the animation is complete, move the previously active tile to the end
					setTimeout(function () {
						current.addClass(noanimate).removeClass(prevclass).removeClass(ltrani);
						current = next;
						active = num;
						items.removeClass(noanimate);
						current.removeClass(ltrani);
						canclick = true;
						ani = false;
					}, speed);
					// Change carousel nav
					navitems.removeClass('is-active');
					$(navitems[num]).addClass('is-active');
				}

				// Bind nav clicks to nav items
				function bindNavClicks() {
					links = nav.find('.thumbnail-link');
					links.each(function() {
						$(this).on('click', function(event) {
							event.preventDefault();
							if( canclick === true ) {
								canclick = false;
								var i;
								for (i = 0; i < links.length; i += 1) {
									if( links[i] === this ) {
										break;
									}
								}
								if ( i === active ) {
									canclick = true;
								}
								else if( i > active ) {
									animateTray(i);
								}
								else if( i < active ) {
									animateTray(i);
								}
							}
						});
					});
				}

				// Build item nav & nav arrows
				function buildNav() {
					nav = obj.append('<nav class="thumbstrip"/>').find('.thumbstrip');
					items.each(function() {
						navbtn = $(this).find('.thumbnail');
						navitem = nav.append('<a class="thumbnail-link" href="#"></a>').find('.thumbnail-link:last-child');
						navbtn.detach().appendTo(navitem);
					});
					navitems = nav.find('.thumbnail');
					$(navitems[0]).addClass(activeclass);
					// Start the animation
					bindNavClicks();
				}

				// Bind prev & next button clicks
				function bindArrowClicks() {
					prev.on('click', function(event) {
						event.preventDefault();
						if( canclick === true ) {
							canclick = false;
							animateTray(active-1, true);
						}
					});
					next.on('click', function(event) {
						event.preventDefault();
						if( canclick === true ) {
							canclick = false;
							animateTray(active+1, true);
						}
					});
				}

				// Build prev & next buttons
				function buildArrows() {
					arrows = obj.append('<nav class="gallery-arrows"></nav>').find(".gallery-arrows");
					prev = arrows.append('<a class="gallery-btn gallery-btn-prev" href="#"></a>').find(".gallery-btn-prev");
					next = arrows.append('<a class="gallery-btn gallery-btn-next" href="#"></a>').find(".gallery-btn-next");
					bindArrowClicks();
				}

				function startGallery() {
					// Build assets (only if it's not being viewed within
					// the Sitecore Experience Editor and there is more than one item)
					if ( !$('body').hasClass('sc-editor') && items.length > 1 ) {
						buildArrows();
						buildNav();
					}
					$(items[0]).addClass(activeclass+" "+noanimate);
					setTimeout(function() {
						$(items[0]).removeClass(noanimate);
					}, 50 );
					obj.addClass('is-visible');
					// After first image is loaded, set height of gallery
						function checkImg() {
							setTimeout(function() {
								setDimensions(0);
							}, 10);
						}
						checkImg();
					}

				// Set element dimensions
				setDimensions(active);

				// Set element dimensions on window resize
				$(window).on('resize', function() {
					setDimensions(active);
				});

				// Start the gallery
				startGallery();

			});
		},


		// Pseudo dropdown love
		resources_selector_linky : function(el) {
			$(el).each(function() {
				var
					obj = $(this),
					toggle = obj.find(".select-toggle"),
					items = obj.find("li a"),
					button = obj.find(".btn"),
					selectedText = obj.find(".select-toggle div");
				// Add default link to button on page load
				function btnHref(href) {
					button.attr("href", href);
				}
				btnHref($(items[0]).attr("href"));
				// When an item is clicked on
				items.on("click", function(event) {
					event.preventDefault();
					// Get the linky
					var link = $(this).attr("href"), text = $(this).text();
					// Place it in the button
					btnHref(link);
					selectedText.html(text);
					toggle.addClass("is-closed");
					obj.find(".shown").removeClass("shown");
					setTimeout(function(){
						toggle.removeClass("is-closed");
					}, sef.duration( obj.find("ul")) );
				});
			});
		},

		// Prevent widowed words. Ignores words over 10 characters
		widown_t : function(el) {
			$(el).each(function() {
				var nodes = $(this).find('h1');

				function wordCount(str) {
					return str.split(" ").length;
				}

				nodes.each(function() {
					var
						txt = $(this).text(),
						count = wordCount(txt);

					// If there are more than two words
					if ( count === 2 ) {

						// If the word count is over 11 characters, add the
						// non-breaking space
						if ( txt.length < 13 ) {
							// Replace the last space in each title with a non-breaking space
							$(this).html(txt.replace(/\s([^\s<]+?)\s*$/,'&nbsp;$1'));
						}
					}
					else if ( count > 2 ) {
						// Replace the last space in each title with a non-breaking space
						$(this).html(txt.replace(/\s([^\s<]+?)\s*$/,'&nbsp;$1'));
					}

					// Replace all ampersands with a non-breaking space & ampersand
					$(this).html($(this).text().replace("& ", "&#38;&nbsp;"));

					// Replace all hyphens with non-breaking hyphens
					$(this).html($(this).text().replace("-", "&#8209;"));

					// Check the width of the headline and make sure it's not
					// over 50% of its parent
					if ( $(this).outerWidth() > ($(this).parent().outerWidth()/1.75) ) {
						$(this).addClass("smallest-text");
					}
					else if ( $(this).outerWidth() > ($(this).parent().outerWidth()/2) || txt.length > 20 ) {
						$(this).addClass("smaller-text");
					}
				});
			});
		},

		//
		// Get the transition-duration property from the requested element
		//
		duration : function(el) {
			var duration = $(el).css('transition-duration');
			// If the property doesn't exist, use the default
			if( !duration ) {
				duration = 500;
			}
			// Convert CSS transition-duration to milliseconds
			else {
				duration = (duration.indexOf("ms")>-1) ? parseFloat(duration) : parseFloat(duration)*1000;
			}
			// Return the ms calculation
			return duration;
		}


	};
}());

//
//D&B Personalization
//
var cookieParam = {
	name: "SICIndustryCode",
	shownName: "SICIndustryCodeShown",
	param: {
		path: '/',
		expires: 365
	}
};

var isMobile = function () {
	if ($('#dialog').css('display') === 'none') {
		return true;
	}

	return false;
}

var ShowPopup = function (data) {
	$("#dialog").dialog({
		autoOpen: false,
		modal: true,
		width: '960px',
		open: function () {
			jQuery('.ui-widget-overlay').bind('click', function () {
				jQuery('#dialog').dialog('close');
			})
		}
	});

	$(window).resize(function () {
		$("#dialog").dialog("option", "position", {
			my: "center",
			at: "center",
			of: window
		});
	});

	$("#dialog").html(data);
	$(".ui-dialog-titlebar").hide();
	$("#dialog").dialog("open");
}


var DnbPersonalization = function () {

	if ($('body').hasClass('sc-editor')) {
		return;
	}

	if (!$("#dialog").length) {
		return;
	}

	dnbvid.getData("api4638", "json", "T", function (dnb_Data) {
		//Mapping goes here
		//Basic example: myVar = dnb_Data.duns
		//Concatenated example: myVar = dnb_Data.companyName + " | " + dnb_Data.country
		//Advanced example: myVar = dnb_Data.naicsCodes.substr(1,5)
		var cookieName = cookieParam.name;
		var isNewSicCode = $.cookie(cookieName) !== dnb_Data.sicCodes;
		$.cookie(cookieName, dnb_Data.sicCodes, cookieParam.param);
		ProcessDnbData(dnb_Data.sicCodes, isNewSicCode);
	});
}

var ProcessDnbData = function (sicCodes, isNewSicCode) {

	var cookieShownName = cookieParam.shownName;
	if (!isNewSicCode && $.cookie(cookieShownName) === "true") {
		return;
	}

	$.post('/api/sitecore/DNBVisitor/PersonalizedCtaSlider', { industryId: sicCodes },
		function (data) {
				$.cookie(cookieShownName, true, cookieParam.param);
				ShowPopup(data);
		})
		.fail(function (errorData) {
			console.log("error", errorData);
		});
}
