window.sef = window.sef || {};
window.sef.swiffy = {};

window.sef.swiffy.ResizeWindow = function() {
	window.addEventListener("resize", window.sef.swiffy.MobileCheck);
	window.sef.swiffy.MobileCheck();
};

window.sef.swiffy.MobileCheck = function() {
	var sw = document.getElementById("swiffycontainer");
	if (sw === null || sw === undefined) {
		console.log("swiffycontainer DIV HTML is missing");
	} else if (window.innerWidth < 850) {
		sw.setAttribute("style", "width:90%; height:240px; margin-bottom:50px");
	} else {
		sw.setAttribute("style", "width:800px; height:480px;");
	}
};
