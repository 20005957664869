var $;
var window;

// Part Finder

$(document).ready(function () {
	"use strict";

	$("#partfinder-brand-menu").on("change",
		function () {
			if (this.value.trim()) {
				var url = $(this).parent().attr("action");
				var branch = encodeURIComponent(this.value);
				url = url.replace("SEARCHTERM", branch);
				window.open(url);
			}
		});

	$("#partfinder-partnumber-btn").on("click",
		function (event) {
			event.preventDefault();
			var url = $(this).parent().attr("action");
			var partsearch = encodeURIComponent($("#partfinder-partnumber-term").val());
			url = url.replace("SEARCHTERM", partsearch);
			window.open(url);
		});

	$("#partfinder-keyword-btn").on("click",
		function (event) {
			event.preventDefault();
			var url = $(this).parent().attr("action");
			var keyword = encodeURIComponent($("#partfinder-keyword-term").val());
			url = url.replace("SEARCHTERM", keyword);
			window.open(url);
		});
});
